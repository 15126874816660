import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import router from "@/router"; // Import router for navigation fixes

const storeStatus = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const storeData = store.getters.getStoreData;
    const token = to.query.token as string | undefined;

    if (token) {
        store.commit(Mutations.SET_ADMIN_TOKEN, token);
        await router.replace({ path: to.path, query: { ...to.query, token: undefined } });
        return next();
    }

    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    setTimeout(() => window.scrollTo(0, 0), 100);

    return next();
};

export default storeStatus;
