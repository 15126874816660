import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface MenuItem {
    id: number;
    title: string;
    slug: string;
    icon: string;
    position: string;
    sort_order: number;
    parent_id: number;
    status: boolean;
    created_at: string;
    updated_at: string;
    menuable: {
        type: string;
        id: number;
        url: string | null;
        target: string | null;
    };
}

@Module({ namespaced: true })
export default class CmsModule extends VuexModule {
    menuItems: MenuItem[] = [];
    pageData: any = {}
    /**
     * Get all menu items
     * @returns Array<MenuItem>
     */
    get getMenuItems(): MenuItem[] {
        return this.menuItems;
    }

    get getPageData(): any {
        return this.pageData;
    }

    get getMenuableIdBySlug() {
        return (slug: string): number | null => {
            const item = this.menuItems.find((menuItem) => menuItem.slug === slug);
            return item ? item.menuable.id : null;
        };
    }
    /**
     * Get menu items filtered by position
     * @returns Array<MenuItem>
     */
    get getMenuByPosition() {
        return (position: string): MenuItem[] => {
            return this.menuItems.filter((item) => item.position === position);
        };
    }

    @Mutation
    [Mutations.SET_MENU_ITEMS](menuItems: MenuItem[]) {
        this.menuItems = menuItems;
    }

    /**
     * Set page data
     */
    @Mutation
    [Mutations.SET_PAGE_DATA](pageData: any) {
        this.pageData = pageData;
    }

    @Action
    [Actions.FETCH_PAGE_DATA](pageData: any) {
        this.context.commit(Mutations.SET_MENU_ITEMS, pageData);
    }


    @Action
    [Actions.FETCH_MENU_ITEMS](menuData: MenuItem[]) {
        this.context.commit(Mutations.SET_MENU_ITEMS, menuData);
    }


}