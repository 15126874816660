import { App } from "vue";
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";
import useNotyf from "@/composable/useNotyf";
import { translate } from "../helpers/custom";
import router from "@/router";
/**
 * @description service to call HTTP request via Axios
 */
class APICustomEdit {
    /**
     * @description Axios instance (Separate from VueAxios)
     */
    public static axiosInstance: AxiosInstance = axios.create();

    /**
     * @description initialize axios with interceptors
     */
    public static init(app: App<Element>) {
        const notification = useNotyf();

        APICustomEdit.axiosInstance.interceptors.request.use(
            (config) => {
                const adminToken = store.getters.adminToken;
                config.headers["Authorization"] = `Bearer ${adminToken}`;
                config.baseURL = `${process.env.VUE_APP_API_URL}/api/backend/v1`;
                config.headers["Content-Type"] = "application/json";
                config.headers["Accept"] = "application/json";

                return config;
            },
            (error) => Promise.reject(error)
        );

        APICustomEdit.axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                const code = error.response?.status;
                let message = "";

                switch (code) {
                    case 400:
                        message = error?.response?.data?.message ?? "Bad Request";
                        break;
                    case 401:
                        message = "Unauthorized Access";
                        store.commit("clearAdminToken");
                        router.push({ name: "home" });
                        break;
                    case 404:
                        message = error?.response?.statusText ?? "Not Found";
                        break;
                    case 422:
                        message = (Object.values(error?.response?.data?.errors ?? {})[0] ??
                            "Unprocessable Content") as string;
                        break;
                    case 429:
                        message = error?.response?.data?.message ?? "Too Many Requests";
                        break;
                    case 500:
                        message = error?.response?.data?.message ?? "Internal Server Error";
                        break;
                    default:
                        message = error?.response?.data?.message ?? "Unexpected Error Occurred";
                        break;
                }

                if (error.toJSON().message === "Network Error") {
                    console.error("Network Error:", error.toJSON().message);
                }

                if (message !== "") notification.error(translate(message));

                return Promise.reject(error.response);
            }
        );
    }

    /**
     * @description remove authorization header
     */
    public static removeHeader(): void {
        console.log("Removing Authorization Header");
        APICustomEdit.axiosInstance.defaults.headers["Authorization"] = "";
    }

    /**
     * @description send the GET HTTP request
     */
    public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.get(resource, params);
    }

    /**
     * @description send the GET HTTP request
     */
    public static get(resource: string, slug = ""): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.get(`${resource}/${slug}`);
    }

    /**
     * @description set the POST HTTP request
     */
    public static post(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.post(`${resource}`, params);
    }

    /**
     * @description send the UPDATE HTTP request
     */
    public static update(resource: string, slug: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     */
    public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return APICustomEdit.axiosInstance.delete(resource);
    }

    /**
     * @description Get Axios instance
     */
    public static instance() {
        return APICustomEdit.axiosInstance;
    }
}

export default APICustomEdit;
