import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default function isAuth({ next, to }) {
  const isUserAuthenticated = store.getters.isUserAuthenticated;
  const isAuthRequire = to.meta.isAuth;
  const isUnauthenticated = !isUserAuthenticated && isAuthRequire;

  if (isUnauthenticated) {

    store.commit(Mutations.SET_MENU_TYPE, "Home");
    next({
      name: "home",
    });
  } else {
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    // store.dispatch(Actions.VERIFY_AUTH, {});
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    next();
  }
}
